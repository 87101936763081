<template>
  <svg viewBox="0 0 172 167"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.7"
       clip-path="url(#clip0_817_18168)"
    >
      <path d="M23.5168 26.4241L13.2012 41.8811L48.4549 64.7783L58.7706 49.3213L23.5168 26.4241Z"
            fill="#FF8078"
      />
      <path d="M126.563 103.132L113.298 116.218L143.137 145.654L156.402 132.569L126.563 103.132Z"
            fill="#FF8078"
      />
      <path d="M42.2725 80.2127L0.496582 86.0928L3.14627 104.413L44.9221 98.5334L42.2725 80.2127Z"
            fill="#FF8078"
      />
      <path d="M170.15 60.9939L128.05 63.9783L129.395 82.4369L171.495 79.4526L170.15 60.9939Z"
            fill="#FF8078"
      />
      <path d="M54.3239 113.109L31.1567 147.915L46.8377 158.072L70.0048 123.266L54.3239 113.109Z"
            fill="#FF8078"
      />
      <path d="M123.678 8.91847L100.511 43.7246L116.192 53.8822L139.359 19.0761L123.678 8.91847Z"
            fill="#FF8078"
      />
      <path d="M101.682 122.815L83.1975 125.971L90.3947 166.991L108.879 163.835L101.682 122.815Z"
            fill="#FF8078"
      />
      <path d="M80.1265 0.000929034L61.6421 3.15723L68.8393 44.177L87.3237 41.0207L80.1265 0.000929034Z"
            fill="#FF8078"
      />
    </g>
    <defs>
      <clipPath id="clip0_817_18168">
        <rect width="171"
              height="167"
              fill="white"
              transform="translate(0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
